
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import moment from "moment";

export default defineComponent({
  name: "organisation-profile",
  components: {
    VueQrcode,
  },
  props: [""],
  data() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const organisation_id = route.params.organisation_id ?? null;

    const organisation = {
      name: "",
      address: "",
      latitude: "",
      longitude: "",
      org_type: null,
      raw: null,
      parent_id: null,
      allocated_slot: null,
      enabled: 1,
    };

    return {
      dataReady: false,
      store,
      organisation,
      organisation_id,
    };
  },
  methods: {
    init() {
      if (this.organisation_id !== null) {
        this.store
          .dispatch(Actions.GET_ORGANISATION, this.organisation_id)
          .then(() => {
            this.organisation = this.store.getters.getOrganisationData;

            this.dataReady = true;
          });
      } else {
        this.dataReady = true;
      }
    },
    generateReffNumber() {
      console.log();
      return moment().format("MMYY");
    },
  },
  async mounted() {
    this.init();
  },
});
